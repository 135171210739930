import { useState, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import useSWR from 'swr';

import { useJune } from '../lib/hooks';
import { magic } from '../lib/magic';
import { UserContext } from '../lib/UserContext';

const AUTH_STATES = {
  INITIAL: 'INITIAL',
  AUTHENTICATING: 'AUTHENTICATING',
  AUTHENTICATED: 'AUTHENTICATED',
  UNAUTHENTICATED: 'UNAUTHENTICATED',
};

const PUBLIC_ROUTES = [
  '/',
  '/login',
  '/about',
  '/terms',
  '/privacy',
  '/guides/homeowners-guide',
  '/guides/maintenance-guide',
];

const fetchUser = async () => {
  try {
    const res = await fetch('/api/user', {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!res.ok) {
      throw new Error('Failed to fetch user');
    }

    const data = await res.json();

    return { user: data?.user || null };
  } catch (error) {
    console.error('[UserProvider] Error fetching user:', error);
    return { user: null };
  }
};

export const UserProvider = ({ children }) => {
  const [mounted, setMounted] = useState(false); // handle rendering
  const [authState, setAuthState] = useState(AUTH_STATES.INITIAL);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);

  const router = useRouter();
  const analytics = useJune(process.env.NEXT_PUBLIC_JUNE_WRITE_KEY);

  // const initialData = useMemo(() => ({ user }), [user]);

  const { data, error, mutate } = useSWR(
    authState === AUTH_STATES.AUTHENTICATED ? '/api/user' : null,
    fetchUser,
    {
      revalidateOnFocus: false,
      refreshInterval: 5 * 60 * 1000,
    },
  );

  const isPublicRoute =
    PUBLIC_ROUTES.includes(router.pathname) ||
    router.pathname.startsWith('/p/');

  const login = async ({ didToken, email }) => {
    try {
      setAuthState(AUTH_STATES.AUTHENTICATING);

      if (!didToken && email) {
        didToken = await magic.auth.loginWithEmailOTP({ email });
      }

      const res = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + didToken,
          credentials: 'include',
          sameSite: 'Strict',
        },
      });

      if (res.status === 200) {
        const data = await res.json();
        const userInfo = await magic.user.getInfo();

        setUser(userInfo);
        setToken(data.token);

        setAuthState(AUTH_STATES.AUTHENTICATED);

        router.push('/d');
      } else {
        throw new Error('Login failed');
      }
    } catch (error) {
      console.error('[UserProvider] Login error:', error);
      setAuthState(AUTH_STATES.UNAUTHENTICATED);
      router.push('/login');
    }
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  // Check initial auth state
  useEffect(() => {
    const checkAuth = async () => {
      try {
        setAuthState(AUTH_STATES.AUTHENTICATING);
        const isLoggedIn = await magic.user.isLoggedIn();

        if (isLoggedIn) {
          const [userInfo, tokenResponse] = await Promise.all([
            magic.user.getInfo(),
            fetch('/api/user'),
          ]);

          if (tokenResponse.ok) {
            const {
              user: { token: newToken },
            } = await tokenResponse.json();
            setToken(newToken);
          }

          setUser(userInfo);
          setAuthState(AUTH_STATES.AUTHENTICATED);
        } else {
          setAuthState(AUTH_STATES.UNAUTHENTICATED);
        }
      } catch (error) {
        console.error('[UserProvider] Auth check error', error);
        setAuthState(AUTH_STATES.UNAUTHENTICATED);
      }
    };

    checkAuth();
  }, []);

  // handle routing based on auth state
  useEffect(() => {
    if (typeof window === 'undefined') return;

    if (authState === AUTH_STATES.AUTHENTICATED) {
      if (router.pathname === '/login') {
        router.push('/d');
      }
    } else if (authState === AUTH_STATES.UNAUTHENTICATED && !isPublicRoute) {
      router.push('/login');
    }
  }, [authState, router.pathname]);

  // Handle user data updates
  useEffect(() => {
    if (data?.user && authState === AUTH_STATES.AUTHENTICATED) {
      setUser(data.user);
      if (analytics) {
        try {
          analytics.identify(data.user.issuer, {
            email: data.user.email,
          });
        } catch (error) {
          console.debug('[UserProvider] Analytics blocked:', error);
        }
      }
    }
  }, [data, authState]);

  const logout = async () => {
    try {
      await magic.user.logout();
      setUser(null);
      setToken(null);
      setAuthState(AUTH_STATES.UNAUTHENTICATED);
      router.push('/login');
    } catch (error) {
      console.error('[UserProvider] Logout error', error);
    }
  };

  const refreshUser = async () => {
    try {
      await mutate();
    } catch (error) {
      console.error('[UserProvider] refresh error', error);
    }
  };

  const value = {
    login,
    userLoading: authState === AUTH_STATES.AUTHENTICATING,
    user,
    token,
    logout,
  };

  if (!mounted) {
    return <div style={{ height: '100vh' }} />; // Prevent layout shift
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
