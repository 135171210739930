import { useRouter } from 'next/router';
import { useEffect } from 'react';
import NProgress from 'nprogress';

NProgress.configure({
  minimum: 0.25,
  easing: 'ease',
  // speed: 500,
  showSpinner: false,
});

export const LoadingProgress = () => {
  const router = useRouter();

  useEffect(() => {
    let isFirstLoad = true;

    const handleStart = (url: string) => {
      if (!isFirstLoad && url === window.location.pathname) {
        isFirstLoad = false;
        return;
      }
      NProgress.start();
    };

    const handleComplete = () => {
      NProgress.done();
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, [router]);

  return null;
};
